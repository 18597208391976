<script lang="ts">
    import '$lib/app.css'

    import { Trackers } from '@nomodo/trackers'

    import Footer from '$lib/components/Footer.svelte'
    import SvelteQuery from '$lib/layouts/SvelteQuery.svelte'
    import { initSentry } from '$lib/utils'

    import type { LayoutData } from './$types'

    initSentry()
    const GTM_ID = import.meta.env.VITE_GTM_ID

    export let data: LayoutData
</script>

{#if GTM_ID}
    <Trackers gtmId={GTM_ID} />
{/if}

<SvelteQuery>
    <slot />

    <Footer apps={data.footer.apps} />
</SvelteQuery>

import {
    browserTracingIntegration,
    init,
    replayIntegration,
} from '@sentry/svelte'

import { browser } from '$app/environment'

const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV

export const initSentry = () => {
    if (!browser || !SENTRY_DNS) {
        return
    }

    init({
        dsn: SENTRY_DNS,
        integrations: [
            browserTracingIntegration(),
            replayIntegration({ maskAllInputs: false, blockAllMedia: false }),
        ],
        environment: SENTRY_ENV,
        release: APP_VERSION,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    })
}

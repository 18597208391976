<script lang="ts">
    import { getSiteLink, Site } from '@nomodo/links'
    import { Button, Footer } from '@nomodo/ui'

    export let apps: { title: string; slug: string }[]
</script>

<Footer>
    <div class="mb-4 pt-6">
        {#each apps as app}
            <Button
                class="mb-3 mr-3 text-xs"
                data-cy="footerAppCta-{app.title}"
                href={`/${app.slug}`}
                size="sm"
                variant="dark"
            >
                Want {app.title}
            </Button>
        {/each}
    </div>
    <Button
        class="mb-3 mr-3 text-xs"
        href={`${getSiteLink(Site.status)}/`}
        size="sm"
        variant="dark-link"
    >
        Status page
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="https://blog.nomodo.io"
        size="sm"
        target="_blank"
        variant="dark-link"
    >
        Blog
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="/about"
        size="sm"
        variant="dark-link"
    >
        About us
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="/multicloud"
        size="sm"
        variant="dark-link"
    >
        Multicloud
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="https://discord.gg/eycYCn5tkZ"
        size="sm"
        target="_blank"
        variant="dark-link"
    >
        Discord
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="https://www.facebook.com/profile.php?id=100092461303397"
        size="sm"
        target="_blank"
        variant="dark-link"
    >
        Facebook
    </Button>
    <Button
        class="mb-3 mr-3 text-xs"
        href="https://www.linkedin.com/company/nomodo"
        size="sm"
        target="_blank"
        variant="dark-link"
    >
        LinkedIn
    </Button>
    <div class="pt-4">
        <Button
            class="mb-3 text-xs text-gray-400 hover:text-inherit"
            href="/legal/acceptable-use-policy"
            size="sm"
            variant="dark-link"
        >
            Acceptable Use Policy
        </Button><Button
            class="mb-3  text-xs text-gray-400 hover:text-inherit"
            href="/legal/terms-of-service"
            size="sm"
            variant="dark-link"
        >
            Terms of service
        </Button><Button
            class="mb-3  text-xs text-gray-400 hover:text-inherit"
            href="/legal/privacy-policy"
            size="sm"
            variant="dark-link"
        >
            Privacy Policy
        </Button>
    </div>
</Footer>

<script lang="ts">
    import Container from '../atoms/Container.svelte'
    import Flex from '../atoms/Flex.svelte'
    import Heading from '../atoms/Heading.svelte'

    let classNames = ''
    export { classNames as class }
</script>

<footer class="footer bg-nmd-800 {classNames}" {...$$restProps}>
    <Container>
        <Flex --gap-y="0.5rem">
            <Heading size="6" useDiv>
                NOMODO.IO/ &nbsp;
                <span class="text-gray-200 before:inline">
                    NO MOre DevOps/
                </span>
                &nbsp;
                <span class=" before:inline">Ready-made apps</span>
            </Heading>
        </Flex>
        <slot />
    </Container>
</footer>

<style global lang="postcss">
    .footer {
        padding: theme('spacing.8') 0;

        @media screen(md) {
            padding: theme('spacing.16') 0;
        }
    }
</style>

<script lang="ts">
    import * as Sentry from '@sentry/browser'
    import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query'

    import { browser } from '$app/environment'
    import errors from '$lib/constants/errors'

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                enabled: browser,
                retry: 2,
                refetchOnWindowFocus: false,
            },
            mutations: {
                retry: 2,
                onError: (error) => {
                    const message = (error as Error).message
                    if (errors[message as keyof typeof errors]) {
                        return
                    }

                    Sentry.captureException(error)
                },
            },
        },
    })
</script>

<QueryClientProvider client={queryClient}>
    <slot />
</QueryClientProvider>
